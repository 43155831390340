import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default {
    methods: {
        getIP: function(callback){
            var ip_dups = {};

            //compatibility for firefox and chrome
            var RTCPeerConnection = window.RTCPeerConnection
                || window.mozRTCPeerConnection
                || window.webkitRTCPeerConnection;

            //minimal requirements for data connection
            var mediaConstraints = {
                optional: [{RtpDataChannels: true}]
            };

            var servers = {iceServers: [{urls: "stun:stun.services.mozilla.com"}]};

            //construct a new RTCPeerConnection
            var pc = new RTCPeerConnection(servers, mediaConstraints);

            function handleCandidate(candidate){
                //match just the IP address
                var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
                var ip_addr = "";
                
                try {
                    ip_addr = ip_regex.exec(candidate)[1];
                } 
                catch(error){return};
                
                //remove duplicates
                if(ip_dups[ip_addr] === undefined) {
                    store.dispatch(Actions.UPDATE_IP, ip_addr);
                    pc.close();
                    pc = null;
                    callback(ip_addr);
                }
                ip_dups[ip_addr] = true;
            }

            //listen for candidate events
            pc.onicecandidate = function(ice){
                //skip non-candidate events
                if(ice.candidate)
                    handleCandidate(ice.candidate.candidate);
            };

            //create a bogus data channel
            pc.createDataChannel("");

            //create an offer sdp
            pc.createOffer(function(result){
                //trigger the stun server request
                pc.setLocalDescription(result, function(){return}, function(){return});

            }, function(){pc});

            //wait for a while to let everything done
            setTimeout(function(){
                //read candidate info from local description
                var lines = "";
                try {
                    lines = pc.localDescription.sdp.split('\n');
                    lines.forEach(function(line){
                        if(line.indexOf('a=candidate:') === 0)
                            handleCandidate(line);
                    });
                } catch(error){return}
            }, 1000);
        }
    }
}