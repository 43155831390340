
import { defineComponent, ref, onMounted } from "vue";
import SupplyModal from "@/components/modals/forms/SupplyModal.vue";
import ConfirmSupplyModal from "@/components/modals/forms/ConfirmSupplyModal.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { Order, Line } from "@/core/config/tables";
import config from "@/core/config/UtilConfig";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import supplyExternal from "../scripts/supplyExternal.js";

export default defineComponent({
  name: "supply-listing",
  components: {
    SupplyModal,
    ConfirmSupplyModal,
    Datatable,
  },
  setup() {
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(translate("supply"), []);
    });

    return { translate };
  },
  data(){
    return {
      currentOrder: {} as Order,
      orderHeaders: config.orderHeaders,
      orderLines: Array<Line>(),
      key: "init",
      headers: config.lineHeaders,
      orderPage: 1,
      linePage: 1,
      pageSize: 20,
      orderId: 0,
      totalCount: 0,
      showPrevButton: false,
      showNextButton: false,
      showConfirm: true,
      field: "item",
      order: "asc"
    }
  },
  beforeMount(){
    store.dispatch(Actions.CLEAN_CHANGES);
    this.getOrder(this.field, this.order);
  },
  mounted(){
    window.addEventListener("locale-changed", (event) => {
      setCurrentPageBreadcrumbs(this.translate("supply"), []);
      this.key = "lang"+localStorage.getItem("lang");
    });

    const router = useRouter();

    //check if current user is authenticated
    if (!store.getters.isUserAuthenticated) {
      router.push("/supply-login");
    }

    this.getNextOrderLines();

    store.watch(function(){
        return store.getters.getLastLineChange
      }, () => {
        const changeLine = store.getters.getLastLineChange;
        if(changeLine) {
          for(var i= 0; i<this.orderLines.length; i++) {
            if(this.orderLines[i].id == changeLine.id){
              this.orderLines[i].sumQuantity = changeLine.sumQuantity;
              this.orderLines[i].status = changeLine.status;
            }
          }
          this.key = "changeLine"+changeLine.id;
        }
      }
    )
  },
  methods: {
    getOrder: function(field, order) {
      supplyExternal.methods.getIP(function(ip){return})
      setTimeout(() => {
        const params = { params: {ip: store.getters.getIP, util: config.util, status: config.supplyStatus, page: this.orderPage, pageSize: 1, sort: "code:asc"} };
        axios.get(config.host+"/o/headless-supply-box/v1.0/orders", params).
          then(response => {
            if(response.data.items[0]) {
              this.currentOrder = response.data.items[0];
              this.orderId = response.data.items[0].id;
              this.totalCount = response.data.totalCount;
              this.field = field;
              this.order = order;
              this.linePage = 1;

              const params = { params: {ip: store.getters.getIP, util: config.util, page: this.linePage, pageSize: this.pageSize, sort: this.field+":"+this.order} };
              axios.get(config.host+"/o/headless-supply-box/v1.0/orders/"+this.orderId+"/lines", params).
                then(response => {
                  if(response.data.items[0]) {
                    const changeLines = store.getters.getChangeLines;
                    if(changeLines.length > 0) {
                      changeLines.forEach(changeLine => {
                        for(var i= 0; i<response.data.items.length; i++) {
                          if(changeLine.id == response.data.items[i].id) {
                              response.data.items[i].sumQuantity = changeLine.sumQuantity;
                              response.data.items[i].status = changeLine.status;
                            }
                        }
                      });
                    }

                    this.orderLines = response.data.items;
                    this.key = "loadLines"+this.orderId+this.field+this.order;
                    this.linePage += 1;
                  } 
                  else {
                    this.orderLines = Array<Line>();
                    this.key = "empty";    
                  }
              }).catch(e => {
                this.orderLines = Array<Line>();
                this.key = "empty";
              });

              if(this.orderPage > 1) this.showPrevButton = true;
              else if(this.orderPage == 1) this.showPrevButton = false;
            
              if(this.orderPage < this.totalCount) this.showNextButton = true;
              else if(this.orderPage == this.totalCount) this.showNextButton = false;

              this.key = "loadOrder"+this.orderId;
            } 
            else {
              this.currentOrder = {} as Order;
              this.orderLines = Array<Line>();
              this.showPrevButton = false;
              this.showNextButton = false;
              this.showConfirm = false;
              this.key = "empty";
            }
          }).catch(e => {
            this.currentOrder = {} as Order;
            this.orderLines = Array<Line>();
            this.showPrevButton = false;
            this.showNextButton = false;
            this.showConfirm = false;
            this.key = "empty";
          });
      }, 500);
    },
    getNextOrderLines: function() {
      window.onscroll = () => {
        let bottomOfWindow = (window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight;

        if (bottomOfWindow) {
          supplyExternal.methods.getIP(function(ip){return})
          setTimeout(() => {
            const params = { params: {ip: store.getters.getIP, util: config.util, page: this.linePage, pageSize: this.pageSize, sort: this.field+":"+this.order} };
              axios.get(config.host+"/o/headless-supply-box/v1.0/orders/"+this.orderId+"/lines", params).
                then(response => {
                  for(var i= 0; i<response.data.items.length; i++) {
                    this.orderLines.push(response.data.items[i]);
                  }
                  this.linePage += 1;
                  this.key = "nextLines"+this.linePage;
                }).catch(e => console.log(e));
          }, 500);
        }
      }
    },
    getPrevOrder: function() {
      if(this.orderPage > 1) this.orderPage -= 1;
      this.getOrder(this.field, this.order);
    },
    getNextOrder: function(confirm) {
      if(this.orderPage < this.totalCount && !confirm) this.orderPage += 1;
      this.getOrder(this.field, this.order);
    },
    setCurrentOrderLine: async function(orderLine) {
      store.dispatch(Actions.UPDATE_ORDER_LINE, orderLine);
      
      this.key="updateLine"+orderLine.id
      setTimeout(() => { document.getElementById("changeQuantityModal")?.click(); }, 500);
    },
    confirmSupply: function() {
      const changeLines = store.getters.getChangeLines;

      changeLines.forEach(changeLine => {
        axios.patch(config.host+"/o/headless-supply-box/v1.0/lines/"+changeLine.id, changeLine, {params: {ip: store.getters.getIP, email: store.getters.currentUser.email, util: config.util}});
      });

      axios.patch(config.host+"/o/headless-supply-box/v1.0/orders/"+this.currentOrder.id, {status: "Suministrado"}, {params: {ip: store.getters.getIP, email: store.getters.currentUser.email, util: config.util}}).
        then(response => {
          store.dispatch(Actions.CLEAN_CHANGES);
          if(!this.showNextButton) {
            if(this.showPrevButton) this.getPrevOrder();
            else this.getOrder(this.field, this.order);
          } 
          else {
            this.getNextOrder(true);
          }
        }).catch(e => console.log(e));
    },
    showWarning: function(step) {
      const changeLines = store.getters.getChangeLines;
      if(changeLines.length > 0) {
        Swal.fire({
          text: this.translate("doYouWantToChangeOrder"),
          icon: "warning",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: this.translate("yes"),
          cancelButtonText: this.translate("no"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-warning",
            cancelButton: "btn"
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch(Actions.CLEAN_CHANGES);
            if(step == "prev") this.getPrevOrder();
            else if(step == "next") this.getNextOrder(false);
          }
        });
      } else {
        if(step == "prev") this.getPrevOrder();
        else if(step == "next") this.getNextOrder(false);
      }
    },
  },
});
