
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "supply-modal",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const confirmSupplyModalRef = ref<null | HTMLElement>(null);
    const router = useRouter();
    
    const submit = () => {
      hideModal(confirmSupplyModalRef.value);
    };

    const signOut = () => {
      store.dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "supply-login" }));
    };

    return {
      submit,
      signOut,
      formRef,
      confirmSupplyModalRef,
    };
  }
});
