import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "kt_modal_supply",
  ref: "supplyModalRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-650px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_supply_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "modal-body py-10 px-lg-17" }
const _hoisted_7 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_supply_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_supply_header",
  "data-kt-scroll-wrappers": "#kt_modal_supply_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_8 = { class: "fv-row mb-7" }
const _hoisted_9 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_10 = { class: "fv-row mb-7" }
const _hoisted_11 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_12 = { class: "fv-row mb-7" }
const _hoisted_13 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_14 = { class: "fv-row mb-7" }
const _hoisted_15 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_16 = { class: "fv-row mb-7" }
const _hoisted_17 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_18 = { class: "fv-row mb-7" }
const _hoisted_19 = { class: "fs-6 fw-bolder mb-2" }
const _hoisted_20 = { class: "fv-row mb-7" }
const _hoisted_21 = { class: "required fs-6 fw-bolder mb-2" }
const _hoisted_22 = { class: "modal-footer flex-center" }
const _hoisted_23 = {
  id: "kt_modal_supply_cancel",
  class: "btn btn-light me-3",
  "data-bs-dismiss": "modal"
}
const _hoisted_24 = {
  class: "btn btn-lg btn-primary",
  type: "submit"
}
const _hoisted_25 = { class: "indicator-label" }
const _hoisted_26 = { class: "svg-icon svg-icon-3 ms-2 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translate("changeQuantity")), 1)
        ]),
        _createVNode(_component_el_form, {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
          model: _ctx.orderLine,
          rules: _ctx.rules,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.translate("item")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.item), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.translate("description")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.description), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.translate("orderedQuantity")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.ordQuantity), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.translate("agreedQuantity")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.agrQuantity), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.translate("status")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.status), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.translate("unit")) + ":", 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.orderLine.units), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.translate("suppliedQuantity")) + ":", 1),
                  _createVNode(_component_el_form_item, { prop: "sumQuantity" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: _ctx.orderLine.sumQuantity,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderLine.sumQuantity) = $event)),
                        type: "number"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("button", _hoisted_23, _toDisplayString(_ctx.translate("cancel")), 1),
              _createElementVNode("button", _hoisted_24, [
                _createElementVNode("span", _hoisted_25, [
                  _createTextVNode(_toDisplayString(_ctx.translate("accept")) + " ", 1),
                  _createElementVNode("span", _hoisted_26, [
                    _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr064.svg" })
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ])
    ])
  ], 512))
}