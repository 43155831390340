
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "supply-modal",
  components: {},
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const supplyModalRef = ref<null | HTMLElement>(null);

    const orderLine = store.getters.getOrderLine;

    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    }

    const rules = ref({
      sumQuantity: [
        {
          required: true,
          message: translate("quantityRequired"),
          trigger: "change",
        },
      ]
    });

    let confirm = false;
    
    const submit = () => {
      if(orderLine.sumQuantity > orderLine.ordQuantity && !confirm) {
        Swal.fire({
          text: translate("quantityBiggerThanOrdered"),
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: translate("accept"),
          customClass: {
            confirmButton: "btn fw-bold btn-light-warning",
          },
        });

        confirm = true;
      } 
      else {
        const changeLine = {
          id: orderLine.id, 
          sumQuantity: orderLine.sumQuantity, 
          status: orderLine.sumQuantity == 0 ? "No sum" : (orderLine.sumQuantity < orderLine.ordQuantity ? "Parcial" : "Suministrada")
        }

        store.dispatch(Actions.NEW_ORDER_LINE_CHANGE, changeLine);
        
        hideModal(supplyModalRef.value);
      }
    };

    return {
      orderLine,
      rules,
      submit,
      formRef,
      supplyModalRef,
      translate
    };
  },
});
